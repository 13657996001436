// // Set your brand colors
// $purple: #8a4d76;
// $pink: #fa7c91;
// $brown: #757763;
// $beige-light: #d0d1cd;
// $beige-lighter: #eff0eb;
// Path to Bulma's sass folder
// @use "bulma/sass" with (
//   $family-primary: '"Nunito", sans-serif',
//   $grey-dark: $brown,
//   $grey-light: $beige-light,
//   $primary: $purple,
//   $link: $pink,
// );
// 1. Override Bulma variables
// Path to Bulma's sass folder
@use "bulma/sass" with (
  $family-primary: '"Nunito", sans-serif',
  $primary: #7e8825,
  // $primary: #FFE600,
  // $light: #F5F5F5,
  // $dark: #1C1C1C,
  // $link: #1DA1F2,
  // $background: #FFFFFF
);

// $primary: #7e8825,  // to je moje


// $link: #c5c96b,
// $info: #e8efcd,
// $success: #23d160,
// $warning: #ffdd57,
// $danger: #473c1e

// Import the Google Font
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");
