.preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}



.preview-content {
  max-height: 80vh;
  margin: 0;
}

.close-button {
  position: relative;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}


  .preview-modal {
    width: calc(60% - 30vw);
    min-width: 400px;
    background: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 90vw;
    max-height: 90vh;
    overflow: hidden;
  }
  
  @media (max-width: 768px) {
    .preview-modal {
      width: calc(60% + 10vw);
    }
  }
  
  /* .preview-modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 90vw;
    max-height: 90vh;
    overflow: hidden;
  } */
  