.no-wrap {
    white-space: nowrap;
  }
  


.process-container span {
    margin-right: 20px;
    display: block;
}

.process-container p {
    font-size:x-large;
    margin-bottom: 0.5em;
}
.process-container .icon {
    color: var(--bulma-primary);
    font-size: x-large;
    padding-right: 2em;
    padding-top: 0.2em;

}
.navbar-burger {
    color: var(--bulma-primary);
}
@media screen and (min-width: 1024px) {
    .navbar-center {
        display: flex;
        flex-grow: 1;
        justify-content: center;
    }
}

@media screen and (min-width: 1024px) {
.custom-box {
    border: 1px solid var(--bulma-primary);
    box-shadow: 1px 2px 5px rgba(var(--bulma-primary-rgb), 0.3);
  }
} 

.icontext {
    display: flex;
    align-content: flex-start;
    margin-bottom: 1em;
}
.icontext i {
    color: var(--bulma-primary);
    font-size: x-large;
    padding-top: 0.3em;
    margin-left: 1em;

}
.icontext span {
    font-size: x-large;
    line-height: 1.1em;
    margin-left: 1em;
}

