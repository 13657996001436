.no-wrap {
    white-space: nowrap;
  }

.container-content {
    margin-top: 52px;
    margin-bottom: 100px;
}

.container-qr {
    background-color: rgb(255, 255, 255, 1); /* Ensures the watermark is not visible in this area */
    /* min-width: 50%; */
    margin: 50px auto;
}

.container-qr p {
    font-size: 20px;
    margin-bottom: 10px;
}
.qr-code {
    background-color: white;
    margin-bottom: 20px;
    border: 1px solid black;
    border-radius: 15px;
    padding: 20px;
    display: inline-block; /* Ensure the container fits the image size */
    overflow: hidden; /* Hide any overflow */
    text-align: center; /* Center the content inside */
}

.qr-code img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;}

table {
    text-align: left;
}

td {
    border: none;
    padding: 0.0rem 1rem;
    text-align: left;
    font-size: 1.2em;
    white-space: no-wrap;
}
.button-container {
    width: 60%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem; /* Adds space between buttons */
  }
  
  .button-container .button {
    flex: 1;
    width: 100%; /* Ensures buttons take up the same width */
  }
  